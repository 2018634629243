import encodeArguments from '@/lib/encodeArguments';

export const AUTH_ENDPOINT = '/api/User';
export const TERMINATE_USER_ENDPOINT = `${AUTH_ENDPOINT}/terminate`;
export const LOGIN_ENDPOINT = `${AUTH_ENDPOINT}/login`;
export const LOGOUT_ENDPOINT = `${AUTH_ENDPOINT}/logout`;
export const TOTP_ENDPOINT = `${AUTH_ENDPOINT}/totp`;
export const SETUP_TOTP_ENDPOINT = `${AUTH_ENDPOINT}/setUpTotp`;
export const DISABLE_TOTP_ENDPOINT = `${AUTH_ENDPOINT}/disableTotp`;
export const BACKUPCODE_TOTP_ENDPOINT = `${TOTP_ENDPOINT}/backupCode`;
export const CHANGE_PASSWORD = `${AUTH_ENDPOINT}/changePassword`;
export const EXTEND_SESSION = `${AUTH_ENDPOINT}/extendSession`;
export const SET_SESSION_DURATION = `${AUTH_ENDPOINT}/setSessionDuration`;
export const RECOVERY_ENDPOINT = '/api/Recovery';
export const REQUEST_RECOVERY_EMAIL = `${RECOVERY_ENDPOINT}/request_recovery_email`;
export const RESET_PASSWORD = `${RECOVERY_ENDPOINT}/reset_password`;
export const ENABLE_RECOVERY_ADDRESS = `${RECOVERY_ENDPOINT}/enable_recovery_address`;
export const CONFIRM_RECOVERY_ADDRESS = `${RECOVERY_ENDPOINT}/confirm_recovery_address`;
export const RECOVERY_STATUS = `${RECOVERY_ENDPOINT}/status`;
export const DISABLE_RECOVERY_ADDRESS = `${RECOVERY_ENDPOINT}/disable_recovery_address`;
export const NEW_MAIL_NOTIFICATIONS = '/api/NewMailNotifications';
export const ENABLE_RECOVERY_KEY = `${RECOVERY_ENDPOINT}/enable_recovery_key`;
export const DISABLE_RECOVERY_KEY = `${RECOVERY_ENDPOINT}/disable_recovery_key`;
export const AVAILABLE_ADDRESSES_ENDPOINT = encodeArguments(
  (requestedEmail: string) => `/api/AvailableAddresses/${requestedEmail}`
);
export const SIGNUP_ENDPOINT_V2 = '/api/Signups_v2';
export const SIGNUP_RECOVERY_CODE = '/api/SignupRecoveryCode';
export const SIGNUP_FEEDBACK = '/api/SignupFeedback';
export const SIGNUP_DOMAIN_VERIFICATION = '/api/SignupDomainVerification';
export const STATUS_UPDATE = '/api/StatusUpdate';
export const EMPTY_TRASH = '/api/Trash/empty';
export const EMPTY_JUNK = '/api/Junk/empty';
export const CHECK_KEYS_ENDPOINT = '/api/CheckKeys2';
export const KEYS_ENDPOINT = '/api/PGPKeys';
export const KEY_ENDPOINT = encodeArguments(
  (fingerprint: string) => `${KEYS_ENDPOINT}/${fingerprint}`
);
export const KEY_ENDPOINT_REFRESH = `${KEYS_ENDPOINT}/refresh`;
export const DOWNLOAD_PUBLIC_KEY_ENDPOINT = encodeArguments(
  (fingerprint: string) => `${KEYS_ENDPOINT}/${fingerprint}/downloadPublic`
);
export const DOWNLOAD_SECRET_KEY_ENDPOINT = encodeArguments(
  (fingerprint: string) => `${KEYS_ENDPOINT}/${fingerprint}/downloadSecret`
);
export const KEY_CHANGE_PASSPHRASE = encodeArguments(
  (fingerprint: string) => `${KEYS_ENDPOINT}/${fingerprint}/changePassphrase`
);
export const CONTACT_ENDPOINT = encodeArguments(
  (id: string) => `${CONTACTS_ENDPOINT}/${id}`
);
export const CONTACTS_ENDPOINT = '/api/Contacts';
export const CONTACTS_IMPORT_ENDPOINT = `${CONTACTS_ENDPOINT}/import`;
export const CONTACTS_EXPORT_ENDPOINT = '/api/Contacts/vCard';
export const CONTACTS_EXPORT_GROUP = encodeArguments(
  (id: string, file_name: string) =>
    `${CONTACTS_EXPORT_ENDPOINT}?group_id=${id}&file_name=${file_name}`
);
export const CONTACT_GROUPS_ENDPOINT = '/api/ContactGroups';
export const CONTACT_GROUP_ENDPOINT = encodeArguments(
  (id: string) => `${CONTACT_GROUPS_ENDPOINT}/${id}`
);
export const DOWNLOAD_FILES_ENDPOINT = encodeArguments(
  (id: string, fileName: string) =>
    `${MESSAGES_ENDPOINT}/${id}/files/download?file_name=${fileName}`
);
export const REPORT_FEEDBACK_ENDPOINT = '/api/ReportFeedback';
export const FOLDERS_ENDPOINT = '/api/Folders';
export const FOLDER_ENDPOINT = encodeArguments(
  (id: string) => `/api/Folders/${id}`
);
export const FOLDERS_V2_ENDPOINT = '/api/Folders_v2';
export const MESSAGE_ENDPOINT = encodeArguments(
  (id: string) => `${MESSAGES_ENDPOINT}/${id}`
);
export const MESSAGE_DELETE_ENDPOINT = encodeArguments(
  (id: string) => `${MESSAGES_ENDPOINT}/${id}?move_to_trash=false`
);
export const MESSAGE_DECRYPT_ENDPOINT = encodeArguments(
  (id: string) => `${MESSAGES_ENDPOINT}/${id}/decrypt`
);
export const MESSAGES_ENDPOINT = '/api/Messages';
export const MESSAGE_SOURCE_ENDPOINT = encodeArguments(
  (id: string) => `/api/MessageSources/${id}`
);
export const MESSAGES_BULK_ENDPOINT = `${MESSAGES_ENDPOINT}/bulk`;
export const MESSAGES_REPORT_ENDPOINT = `${MESSAGES_ENDPOINT}/report`;
export const SEND_ENDPOINT = `${MESSAGES_ENDPOINT}/send`;
export const DRAFTS_ENDPOINT = '/api/Drafts';
export const DRAFT_ENDPOINT = encodeArguments(
  (id: string) => `${DRAFTS_ENDPOINT}/${id}`
);
export const DRAFT_FROM_MESSAGE_ENDPOINT = encodeArguments(
  (id: string) => `${DRAFTS_ENDPOINT}/CreateFromMessage/${id}`
);
export const ATTACHMENTS_ENDPOINT = '/api/Attachments';
export const ATTACHMENT_ENDPOINT = encodeArguments(
  (draftId: string, id: string) => `/api/Drafts/${draftId}/Attachments/${id}`
);
export const ATTACHMENT_UPLOAD_ENDPOINT = encodeArguments(
  (draftId: string) => `/api/Drafts/${draftId}/upload`
);
export const RECIPIENTS_SUGGEST_V2_ENDPOINT = '/api/Recipients/suggest_v2';
export const PASSWORD_STRENGTH_ENDPOINT = '/api/PasswordStrength';
export const FEATURES_ENDPOINT = '/api/Features';

export const CHARGEBEE_ENDPOINT = '/api/Chargebee';
export const CHARGEBEE_CANCEL_SUBSCRIPTION = `${CHARGEBEE_ENDPOINT}/CancelSubscription`;
export const CHARGEBEE_CANCEL_SUBSCRIPTION_DEAL = `${CHARGEBEE_CANCEL_SUBSCRIPTION}/Deal`;
export const CHARGEBEE_CHECKOUT_ENDPOINT = `${CHARGEBEE_ENDPOINT}/Checkout`;
export const CHARGEBEE_CHECKOUT_TRIAL_ENDPOINT = `${CHARGEBEE_ENDPOINT}/CheckoutTrial`;
export const CHARGEBEE_CURRENCY = `${CHARGEBEE_ENDPOINT}/Currency`;
export const CHARGEBEE_END_TRIAL_ENDPOINT = `${CHARGEBEE_ENDPOINT}/EndTrial`;
export const CHARGEBEE_EXTEND_TRIAL_ENDPOINT = `${CHARGEBEE_ENDPOINT}/ExtendTrial`;
export const CHARGEBEE_INVOICE = encodeArguments(
  (invoice_id: string) => `${CHARGEBEE_INVOICES}/${invoice_id}.pdf`
);
export const CHARGEBEE_INVOICES = `${CHARGEBEE_ENDPOINT}/Invoices`;
export const CHARGEBEE_INVOICE_DETAILS_ENDPOINT = `${CHARGEBEE_ENDPOINT}/InvoiceDetails`;
export const CHARGEBEE_MANAGE_PAYMENT_SOURCES = `${CHARGEBEE_ENDPOINT}/ManagePaymentSources`;
export const CHARGEBEE_PLAN = `${CHARGEBEE_ENDPOINT}/Plan`;
export const CHARGEBEE_PORTAL_SESSION_ENDPOINT = `${CHARGEBEE_ENDPOINT}/PortalSession`;
export const CHARGEBEE_REACTIVATE_SUBSCRIPTION = `${CHARGEBEE_ENDPOINT}/ReactivateSubscription`;
export const CHARGEBEE_SYNC_SUBSCRIPTION_ENDPOINT = `${CHARGEBEE_ENDPOINT}/SyncSubscription`;

export const AUTO_REPLY_SETTINGS_ENDPOINT = '/api/AutoReply';
export const BROWSER_METRICS_ENDPOINT = '/api/BrowserMetrics';
export const UI_EVENTS_ENDPOINT = '/api/Events';
export const PREFERENCES_ENDPOINT = '/api/Preferences';
export const QUOTA_ENDPOINT = '/api/Quota';
export const ALIASES_ENDPOINT = '/api/Aliases/v4';
export const ALIAS_ENDPOINT = encodeArguments(
  (id: string) => `${ALIASES_ENDPOINT}/${id}`
);
export const FILTERS_ENDPOINT = '/api/Filters';
export const PASSWORD_PROTECTED_MESSAGE_ENDPOINT = encodeArguments(
  (id: string) => `/api/PPM/${id}/decrypt`
);
export const IMAP_TOKENS_ENDPOINT = '/api/ImapTokens';
export const DOMAINS_ENDPOINT = '/api/Domains';
export const DOMAIN_ENDPOINT = encodeArguments(
  (domainName: string) => `${DOMAINS_ENDPOINT}/${domainName}`
);
export const RECENT_RECIPIENT = encodeArguments(
  (recipientId: string) => `/api/Recipients/${recipientId}`
);
export const RECENT_RECIPIENTS = '/api/Recipients';

export const MIGRATION_STATUS = '/api/MigrationStatus';

// Group subscription Endpoints
export const GROUP_SUBSCRIPTIONS = '/api/TeamAccounts';

// Unsubscribe
export const UNSUBSCRIBE = encodeArguments(
  (token: string) => `/api/unsubscribe/${token}`
);
